<template>
  <el-dialog 
    title="详情"
    :visible="visible"
    :close-on-click-modal="true"
    :before-close="handleClose"
    width="600px"
  >
    <el-form :model="formData" ref="form" label-width="150px" size="mini" v-loading="loading">
      <el-form-item label="用户昵称">
        <div> {{formData.userName}} </div>
      </el-form-item>
      <el-form-item prop="contacts" label="联系人">
        <div> {{formData.contacts}} </div>
      </el-form-item>
      <el-form-item prop="contactsPhone" label="联系电话">
        <div> {{formData.contactsPhone}} </div>
      </el-form-item>
      <el-form-item prop="wxorqq" label="其他联系(QQ/WX)">
        <div> {{formData.wxorqq}} </div>
      </el-form-item>
      <el-form-item prop="mailbox" label="邮箱">
        <div> {{formData.mailbox}} </div>
      </el-form-item>
      <el-form-item prop="addressDetail" label="联系地址">
        <div> {{formData.addressDetail}} </div>
      </el-form-item>
      <el-form-item prop="signDate" label="签约日期">
        <div> {{formData.signDate}} </div>
      </el-form-item>
      <el-form-item label="厂家编号">
        <div> {{formData.factoryNum}} </div>
      </el-form-item>
    </el-form> 
    <gl-flex>
      <el-button @click="handleClose">关闭</el-button>
    </gl-flex>
  </el-dialog>
</template>

<script>
import {getUserById} from '@/api/backendall/member'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    itemId: {
      type: String,
      default: '',
    },
  },
  data(){
    return{
      formData: {},
      loading: false,
    }
  },
  watch: {
    visible: {
      handler: function(newVal){
        newVal && this.itemId && this.loadAccountById()
      },
    }
  },
  methods: {
    async loadAccountById(){
      this.loading = true
      const res = await getUserById(this.itemId)
      this.formData = res.data
      this.loading = false
    },
    handleClose(){
      this.$emit('update:visible', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.preview-image{
  display: flex;
  &_item{
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>